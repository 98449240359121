.SkillsSection {
	background-color: #e9e9e9;
	padding: 3em;
}

.iconContainer{
    text-align: center;
    padding: 5% 0;
}

.skills__header {
	text-align: center;
	font-size: 1.8em;
	padding-bottom: 2%;
}

.heading {
	font-size: 1.5em;
}

#cardRoot{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

#cardRoot:hover, #cardRoot:focus, #cardRoot:active {
    background-color: #F0C0A8;
    color: #483030;
    box-shadow: 0 0 8px #603030c8;
}

.paper__heading {
    text-align: center;
}

.paper__text {
	padding: 4%;
	font-size: 1.4em;
	line-height: 1.5em;
    text-align: center;
}
