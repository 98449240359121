.AboutSection {
	/* background-color: #B49C8C; */
	background-color: #c4bbbb;
	padding: 3%;
}

#about__content {
	justify-content: space-around;
	border: 2px solid transparenet;
	background-color: #e9e9e9;
	border-radius: 1em;
	padding: 2% 0;
	box-shadow: 3px 3px 30px #483030;
}

/* .img__container {
	border-radius: 10%;
	box-shadow: 0 0 8px 8px #e9e9e9 inset;
} */

.paragraph_wrapper {
	display: flex;
	flex-direction: column;
}

.paragraph {
	margin: 2% 0;
	font-size: 1.1em;
}

.card__text {
	font-size: 1.1em;
	font-style: italic;
}

.about__header {
	text-align: center;
	font-size: 1.8em;
	color: black;
}

.heading {
	font-family: "Nanum Myeongjo", serif;
	margin: 1%;
}

.img__container {
	align-items: center;
	display: flex;
}
