.navWrapper {
	width: 100vw;
	background-color: #e7e9eb;
    box-shadow: 2px 2px 6px #484848;
	z-index: 1;
    padding: 1% 0;
}

.navbar-links{
	justify-content: space-around;
}

.navLink {
	font-size: 1.3em;
	font-family: "Nanum Myeongjo", serif;
	font-weight: 800;
	text-decoration: none;
	color: #186365;
}

/* Grow */
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    /* color: #903000; */
    color: #8c428c;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}


#logo {
	font-family: "Merienda One", cursive;
}
