.HeroSection {
	background-image: url("../assets/bg2.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hero__wrapper {
	padding: 1.5em;
}
.hero__content {
	font-size: 1.8em;
	font-weight: bold;
	padding: 65vh 0 20vh 0;
}
.hero__heading {
	font-weight: bolder;
	font-size: 2em;
}
.hero__subheading {
	font-size: 1.5em;
}
.hero__heading,
.hero__subheading {
	font-family: "Nanum Myeongjo", serif;
	font-weight: bold;
	color: #c4bdb6;
	text-shadow: -2px 2px #1a0c06;
	margin: 2%;
}
