.ProjectWrapper {
	padding: 2%;
	/* background-color: #d8c0a8; */
	background-color: #e6d8ca;
}
.projects__header {
	padding-bottom: 2%;
	margin: 1%;
	text-align: center;
	font-size: 1.8em;
	color: black;
	font-weight: bold;
}

.hr__styles-pj {
	border-color: #ae8270;
}

.project__container {
	padding: 2% 0;
	justify-content: space-evenly;
	margin: 0 1%;
}

.grid__item {
	padding-bottom: 2%;
}

/* Glow */
.hvr-glow {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px)
		translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: box-shadow;
	transition-property: box-shadow;
}

.hvr-glow:hover,
.hvr-glow:focus,
.hvr-glow:active {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

.project__text {
	padding: 0 2%;
	font-size: 1.1em;
}

.project__title {
	padding-bottom: 1%;
}

.project__content {
	font-size: 1.2em;
}

.project-button__container {
	padding: 3% 0;
	display: flex;
	align-content: center;
	justify-content: flex-end;
}

.btn {
	padding: 2%;
	margin: 0 1%;
	background-color: white;
	border-radius: 0.3em;
}

.btn__link {
	color: #186365;
	text-decoration: none;
	font-size: 1.2em;
}

.btn__link:hover {
	color: #8c428c;
}

/* Outline Out */
.hvr-outline-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px)
		translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	border-radius: 0.3em;
}

.hvr-outline-out:before {
	content: "";
	position: absolute;
	border: white solid 4px;
	border-radius: 0.3em;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: top, right,
		bottom, left;
	transition-property: top, right, bottom, left;
}

.hvr-outline-out:hover:before,
.hvr-outline-out:focus:before,
.hvr-outline-out:active:before {
	top: -8px;
	right: -8px;
	bottom: -8px;
	left: -8px;
}
.animation__container {
	overflow: hidden;
}
