.ContactSection {
	background-color: #e9e9e9;
	padding: 3% 2%;
}

.contact__header{
    text-align: center;
	font-size: 1.8em;
    color: black;
    margin: 1%;
}

.icon__items {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.github__image {
	height: 10vh;
}

.linkedin__image {
	height: 7vh;
}

.cv__image {
	height: 7vh;
    padding: 0 20%;
}
/* Float Shadow */
.hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-float-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 50%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 50%);
    /* W3C */
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
}

.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    /* move the element up by 8px */
}

.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
    opacity: 1;
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
    /* move the element down by 8px (it will stay in place because it's attached to the element that also moves up 5px) */
}
